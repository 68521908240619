export const departmentNamesList = [
  "Civil Engineering",
  "Computer Engineering",
  "Electrical Engineering",
  "Electronics and Telecommunication Engineering",
  "Instrumentation and Control Engineering",
  "Mechanical Engineering",
  "Metallurgy and Materials Science",
  "Manufacturing Engineering and Industrial Management",
  "Planning",
  "Physics",
  "Chemistry",
  "Mathematics",
  "Humanities",
  "Environmental Science",
];

export const verification_options = {
  pending: "pending",
  verified: "verified",
  mod_req: "mod_req",
};

export const roles = {
  phdCord: "phdCord",
  admin: "admin",
  accountSec: "accountSec",
  student: "student",
};

export const docType = {
  photo: "Applicant's Photo",
  sign: "Applicant's Signature",
  ug: "UG Marksheet",
  ugCertificate: "UG Degree Certificate",
  pg: "PG Marksheet",
  pg_degree_certificate: "PG Degree Certificate",
  gate: "GATE Marksheet",
  rpet_certificate: "RPET Certificate",
  net_score_card: "NET Score Card",
  sop: "SOP",
  proofDOB: "Proof of DOB",
  // leaving_certificate: "Leaving Certificate with mention of Indian",
  // passport: "Passport Certificate",
  // birth: "Birth Certificate",
  // domicile: "Domicile Certificate of Maharashtra",

  nationality_c: "Nationality Certificate",

  c_certificate: "Caste Certificate",
  c_validity: "Caste Validity",
  c_ncl: "Non-Creamy Layer Certificate",

  ews: "EWS Certificate",

  doc_physicallyDisable: "Proof of Physical Disability",
  doc_employed: "Proof of Employment",
  doc_domicile: "Domicile Certificate",
};
